import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import PropTypesDashboardPupil from '../../__props__/dashboard-pupil';
import ChartBaselineResults from '../../components/chart-baseline-results';
import PupilTable from '../../components/pupil-table';
import Modal from '../../components/modal';
import YearGroupSelector from '../../components/year-group-selector';

function Dashboard({ data, busy, house }) {
  const [selectedReportCycle, setSelectedReportCycle] = useState(null);
  const [choosePupilModalVisible, setChoosePupilModalVisible] = useState(false);
  const [selectedPupils, setSelectedPupils] = useState([]);
  const navigate = useNavigate();

  const handleChartPointClick = (pupils) => {
    if (pupils?.length > 1) {
      setSelectedPupils(pupils);
      setChoosePupilModalVisible(true);
    } else if (pupils?.length === 1) {
      navigate(`/pupils/${pupils[0].schoolId}`);
    }
  };
  const handleModalCancel = () => setChoosePupilModalVisible(false);
  const handleYearGroupChange = useCallback((_, value) => {
    setSelectedReportCycle(data?.reportCycles.find((rc) => rc.year === value) ?? null);
  }, [data, setSelectedReportCycle]);

  useEffect(() => {
    handleYearGroupChange(null, data?.reportCycles[0]?.year);
  }, [handleYearGroupChange, data]);

  // Quick fix for now.
  // If were busy fetching new dasboard data don't render anything and abort, otherwisee
  // we'll start rendering stale data. Ideally this should not be a prop but rather
  // the useStore should usage that happening the routee should be wrapped into a local
  // useDashboord() hook that encapsulates the required functionality. The state in the router
  // is currently causing multiple unneed renders?!
  // const [dasboard, loading, error ] = useDashboard(...);
  if (busy) {
    return null;
  }

  const multiplePupilsModal = choosePupilModalVisible ? (
    <Modal
      title="Select a pupil"
      onClick={handleChartPointClick}
      visible={choosePupilModalVisible}
      onCancel={handleModalCancel}
    >
      <p className="text-sm text-gray-500">There&apos;s more than one pupil at the data point you selected. Which one do you want to view?</p>
      <ul className="list-disc text-sm text-gray-500">
        {selectedPupils.map((pupil) => (
          <li key={pupil.schoolId}>
            <p>
              <Link to={`/pupils/${pupil.schoolId}`}>{pupil.fullName}</Link>
            </p>
          </li>
        ))}
      </ul>
    </Modal>
  ) : null;
  const yearGroups = data?.reportCycles
    ?.map((reportCycle, i, arr) => ({
      label: reportCycle.year,
      value: reportCycle.year,
      checked: selectedReportCycle
        ? reportCycle.year === selectedReportCycle.year
        : i === 0,
    }));

  return (
    <div className="page--dashboard">
      <h1 className="mb-6 text-4xl tracking-tight font-extrabold text-gray-900 px-4 py-4 bg-wellington-orange">
        {house ? data?.assumedUser?.boardingHouse : 'My Dashboard'}
      </h1>
      <div className="inline-flex justify-between w-full pb-1">
        <h3 className="leading-10 font-bold">{selectedReportCycle?.cycleName ?? 'Unknown Report Cycle'}</h3>
        <YearGroupSelector yearGroups={yearGroups} onChange={handleYearGroupChange} />
      </div>
      <div className="bg-gray-100 p-4">
        <ChartBaselineResults
          hideTitle
          title={selectedReportCycle?.cycleName ?? 'Unknown Report Cycle'}
          myPupils={selectedReportCycle?.tutees ?? []}
          otherPupils={selectedReportCycle?.restOfSchool ?? []}
          onClick={handleChartPointClick}
        />
      </div>
      {multiplePupilsModal}
      <PupilTable
        pupils={selectedReportCycle?.restOfSchool ?? []}
        tutees={selectedReportCycle?.tutees ?? []}
        house={house}
        assumedUser={data?.assumedUser}
      />
    </div>
  );
}

Dashboard.defaultProps = {
  busy: false,
  house: false,
  data: null,
};

Dashboard.propTypes = {
  busy: PropTypes.bool,
  data: PropTypes.shape({
    // tutees: PropTypes.arrayOf(PropTypesDashboardPupil),
    // restOfSchool: PropTypes.arrayOf(PropTypesDashboardPupil),
    // cycleName: PropTypes.string,
    // year: PropTypes.string,
    assumedUser: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      salutation: PropTypes.string,
      initials: PropTypes.string,
      tutees: PropTypes.arrayOf(PropTypes.shape),
      boardingHouse: PropTypes.string,
    }).isRequired,
    reportCycles: PropTypes.arrayOf(
      PropTypes.shape({
        tutees: PropTypes.arrayOf(PropTypesDashboardPupil),
        restOfSchool: PropTypes.arrayOf(PropTypesDashboardPupil),
        cycleName: PropTypes.string,
        year: PropTypes.number,
      }).isRequired,
    ).isRequired,
  }),
  house: PropTypes.bool,
};

export default Dashboard;
