import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import useAuthStore from '../../store/auth';

const selector = (state) => [state.authenticated, state.handleSignIn, state.handleSignOut];

function RequireAuth({ children }) {
  const [authenticated, handleSignIn] = useAuthStore(selector);

  useEffect(() => {
    const checkSession = async () => {
      let user;

      try {
        user = await Auth.currentAuthenticatedUser();
      } catch (err) {
        return Auth.federatedSignIn();
      }

      return handleSignIn(user);
    };

    checkSession();
  }, [handleSignIn]);

  if (authenticated) {
    return children;
  }

  return null;
}

RequireAuth.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RequireAuth;
