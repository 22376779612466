import create from 'zustand';
import API from '../services/api';

export default create((set, get) => ({
  busy: false,
  error: null,
  pupils: {},
  pupilReportCycles: {},
  schoolEmailAddress: '',
  switchUser: (schoolEmailAddress) => {
    set({ schoolEmailAddress });
  },
  getDashboard: async () => {
    set((state) => ({ ...state, busy: true }));

    const { schoolEmailAddress } = get();
    const opts = {};

    if (schoolEmailAddress) {
      opts.queryStringParameters = { schoolEmailAddress };
    }

    try {
      const dashboard = await API.get('/dashboard', opts);

      set((state) => ({ ...state, dashboard }));
    } catch (error) {
      set((state) => ({ ...state, error }));
    } finally {
      set((state) => ({ ...state, busy: false }));
    }
  },
  getHouse: async () => {
    set((state) => ({ ...state, busy: true }));

    const { schoolEmailAddress } = get();
    const opts = {};

    if (schoolEmailAddress) {
      opts.queryStringParameters = { schoolEmailAddress };
    }

    try {
      const house = await API.get('/house', opts);

      set((state) => ({ ...state, house }));
    } catch (error) {
      set((state) => ({ ...state, error }));
    } finally {
      set((state) => ({ ...state, busy: false }));
    }
  },
  getPupil: async (id) => {
    set((state) => ({ ...state, busy: true }));

    const { schoolEmailAddress } = get();
    const opts = {};

    if (schoolEmailAddress) {
      opts.queryStringParameters = { schoolEmailAddress };
    }

    try {
      const pupil = await API.get(`/pupils/${id}`, opts);

      set((state) => ({
        ...state,
        pupils: {
          ...state.pupils,
          [id]: pupil,
        },
      }));
    } catch (error) {
      set((state) => ({ ...state, error }));
    } finally {
      set((state) => ({ ...state, busy: false }));
    }
  },
}));
