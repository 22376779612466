import React from 'react';
import { createRoot } from 'react-dom/client';
import { Amplify, Auth } from 'aws-amplify';
import Router from './router';

import './index.css';

// Configure AWS Amplify.
Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_AWS_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_APP_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_AWS_COGNITO_OAUTH_DOMAIN,
      scope: ['phone', 'email', 'profile', 'openid'],
      redirectSignIn: process.env.REACT_APP_AWS_COGNITO_OAUTH_REDIRECT,
      redirectSignOut: process.env.REACT_APP_AWS_COGNITO_OAUTH_REDIRECT,
      responseType: 'code',
    },
  },
  API: {
    endpoints: [
      {
        name: process.env.REACT_APP_API_NAME,
        endpoint: process.env.REACT_APP_AWS_API_GATEWAY_URL,
        region: process.env.REACT_APP_AWS_API_GATEWAY_REGION,
        custom_header: async () => ({
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        }),
      },
    ],
  },
});

createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Router />
  </React.StrictMode>,
);
