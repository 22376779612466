import { API as Amplify } from 'aws-amplify';

const API_NAME = process.env.REACT_APP_API_NAME;

const API = {
  Error: {
    NOT_AUTHENTICATED: 'not authenticated',
  },

  async get(endpoint, opts) {
    return Amplify.get(API_NAME, endpoint, opts);
  },
};

export default API;
