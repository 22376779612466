import React from 'react';

function Admin() {
  return (
    <div className="page--admin container mx-auto">
      <h1 className="text-3xl leading-6 font-medium text-gray-900 mb-3 mt-3">Admin</h1>
      <p>
        Thank you for exploring the new reporting application. This section
        is still under development.
      </p>
    </div>
  );
}

export default Admin;
