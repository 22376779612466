/* eslint-disable max-len */
import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Chart,
  registerables,
} from 'chart.js';
import { Scatter } from 'react-chartjs-2';
import trendline from 'chartjs-plugin-trendline';
import PropTypesDashboardPupil from '../../__props__/dashboard-pupil';

// Configure Chart.js. It's necessary to register all of the elements used by
// the scatter chart because they are tree-shaken away otherwise.
Chart.register(...registerables, trendline);

const CHART_POINT_COLOR_TUTEE = '#f27d00';
const CHART_POINT_BORDER_COLOR_TUTEE = '#111111';
const CHART_POINT_COLOR_OTHER = 'rgba(0,0,0,0.1)';
const CHART_QUADRANT_COLOR_TOP_LEFT = '#ffffff';
const CHART_QUADRANT_COLOR_TOP_RIGHT = '#eeeeee';
const CHART_QUADRANT_COLOR_BOTTOM_LEFT = '#eeeeee';
const CHART_QUADRANT_COLOR_BOTTOM_RIGHT = '#ffffff';
const CHART_TRENDLINE_COLOR = '#8EB8AC';
const CHART_TRENDLINE_WIDTH = 2;

const getMean = (arr) => arr.reduce((total, val) => total + val, 0) / arr.length;
const getPoint = (pupil) => ({ x: pupil.baselineMark, y: pupil.averageMark, meta: pupil });

function ChartBaselineResults({
  title,
  myPupils,
  otherPupils,
  onClick,
  hideTitle,
}) {
  const allPupils = useMemo(() => [...myPupils, ...otherPupils], [myPupils, otherPupils]);

  // Utility functions to return the mean average of the two chart axes. Used by
  // the quadrants plugin below to determine the mid point of each axis.
  const getBaselineAverage = useCallback(
    () => getMean(allPupils.filter((pup) => pup.averageMark > 10 && pup.baselineMark !== 0).map((p) => p.baselineMark)),
    [allPupils],
  );

  const getResultAverage = useCallback(
    () => getMean(allPupils.filter((pup) => pup.averageMark > 10 && pup.baselineMark !== 0).map((p) => p.averageMark)),
    [allPupils],
  );

  // Utility function to return chart options.
  const getChartOptions = () => ({
    responsive: true,
    maintainAspectRatio: true,
    onClick(event, nodes) {
      // Handle clicks on chart points. Each point has a duplicate because of
      // the hidden set used to produce the trendline so we filter out points
      // from that dataset by index.
      let clicked;

      if (Array.isArray(nodes)) {
        clicked = nodes.filter((node) => node.datasetIndex < 2);
      } else {
        clicked = [nodes];
      }

      onClick(clicked.map((node) => node?.element?.$context?.raw?.meta));
    },
    scales: {
      x: {
        ticks: {
          color: '#111827',
          font: {
            size: 14,
          },
        },
        title: {
          display: true,
          text: 'Baseline',
          color: '#111827',
          padding: 12,
          font: {
            family: 'Euclid Circular A',
            size: 16,
            weight: 500,
          },
        },
      },
      y: {
        ticks: {
          color: '#111827',
          font: {
            size: 14,
          },
        },
        title: {
          display: true,
          text: 'Exam result (%)',
          color: '#111827',
          font: {
            family: 'Euclid Circular A',
            size: 16,
            weight: 500,
          },
        },
      },
    },
    plugins: {
      legend: {
        display: true,
      },
      quadrants: {
        topLeft: CHART_QUADRANT_COLOR_TOP_LEFT,
        topRight: CHART_QUADRANT_COLOR_TOP_RIGHT,
        bottomLeft: CHART_QUADRANT_COLOR_BOTTOM_LEFT,
        bottomRight: CHART_QUADRANT_COLOR_BOTTOM_RIGHT,
      },
      tooltip: {
        callbacks: {
          // Render a tooltip to display the pupil name on hover of a point on the
          // chart. We have a hidden dataset that is used to render a trendline
          // across all other datasets that requires a specific exception to avoid
          // rendering duplicate tooltips.
          label: (obj) => {
            if (obj?.dataset?.id === 'trendline') {
              return null;
            }

            return obj?.raw?.meta?.fullName;
          },
        },
      },
    },
  });

  // Utility function to return pupil data in the format required by Chart.js.
  // The final dataset is included as a hacky way to produce a line of best fit
  // across the entire underlying set of pupils. The points produced by that
  // dataset are invisible.
  const getChartData = () => ({
    datasets: [
      {
        label: `${title} (Tutees)`,
        borderColor: CHART_POINT_BORDER_COLOR_TUTEE,
        backgroundColor: CHART_POINT_COLOR_TUTEE,
        data: myPupils.filter((pup) => pup.averageMark > 10 && pup.baselineMark !== 0)
          .map((pupil) => getPoint(pupil)),
        elements: {
          point: {
            radius: 5,
          },
        },
      },
      {
        label: `${title} (All)`,
        borderColor: CHART_POINT_COLOR_OTHER,
        data: otherPupils.filter((pup) => pup.averageMark > 10 && pup.baselineMark !== 0).map((pupil) => getPoint(pupil)),
        elements: {
          point: {
            radius: 5,
          },
        },
      },
      {
        id: 'trendline',
        label: '',
        data: allPupils.filter((pup) => pup.averageMark > 10 && pup.baselineMark !== 0).map((pupil) => getPoint(pupil)),
        backgroundColor: 'rgba(0,0,0,0)',
        borderColor: 'rgba(0,0,0,0)',
        trendlineLinear: {
          colorMin: CHART_TRENDLINE_COLOR,
          colorMax: CHART_TRENDLINE_COLOR,
          lineStyle: 'line',
          width: CHART_TRENDLINE_WIDTH,
        },
      },
    ],
  });

  // Utility function to return a custom Chart.js plugin to draw quadrants on
  // the scatter chart canvas. The mid points are found from the average value
  // on each axis.
  const getChartQuadrants = () => ({
    id: 'quadrants',
    beforeDraw(chart, args, options) {
      const {
        ctx,
        chartArea: {
          left,
          top,
          right,
          bottom,
        },
        scales: {
          x,
          y,
        },
      } = chart;
      const midX = x.getPixelForValue(getBaselineAverage());
      const midY = y.getPixelForValue(getResultAverage());

      ctx.save();
      ctx.fillStyle = options.topLeft;
      ctx.fillRect(left, top, midX - left, midY - top);
      ctx.fillStyle = options.topRight;
      ctx.fillRect(midX, top, right - midX, midY - top);
      ctx.fillStyle = options.bottomRight;
      ctx.fillRect(midX, midY, right - midX, bottom - midY);
      ctx.fillStyle = options.bottomLeft;
      ctx.fillRect(left, midY, midX - left, bottom - midY);
      ctx.restore();
    },
  });

  // Only attempt to render the chart if we have all required
  // pupils data. Otherwise getChartQuadrants will be consumed
  // with stale empty pupil data.

  return allPupils?.length
    ? (
      <>
        {!hideTitle && <h1>{ title }</h1>}
        <Scatter
          data={getChartData()}
          options={getChartOptions()}
          plugins={[getChartQuadrants()]}
          redraw
        />
      </>
    )
    : <div>Unable to load pupil data</div>;
}

ChartBaselineResults.defaultProps = {
  hideTitle: false,
};

ChartBaselineResults.propTypes = {
  title: PropTypes.string.isRequired,
  myPupils: PropTypes.arrayOf(PropTypesDashboardPupil).isRequired,
  otherPupils: PropTypes.arrayOf(PropTypesDashboardPupil).isRequired,
  onClick: PropTypes.func.isRequired,
  hideTitle: PropTypes.bool,
};

export default ChartBaselineResults;
