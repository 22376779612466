import PropTypes from 'prop-types';

export default PropTypes.shape({
  id: PropTypes.string,
  subject: PropTypes.string,
  comment: PropTypes.string,
  author: PropTypes.string,
  grade: PropTypes.number,
  va: PropTypes.number,
  dwh: PropTypes.string,
  ce: PropTypes.string,
  iis: PropTypes.string,
  cdl: PropTypes.string,
});
