import React from 'react';
import PropTypes from 'prop-types';

function ButtonGroup({
  id,
  children,
}) {
  return children ? (
    <ul id={id} className="w-max inline-flex items-center -space-x-px">
      {React.Children.map(children, (child, i) => (
        <li className="border first:rounded-l-lg last:rounded-r-lg overflow-auto">
          {child}
        </li>
      ))}
    </ul>
  ) : null;
}

ButtonGroup.defaultProps = {
  children: null,
};

ButtonGroup.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string.isRequired,
};

export default ButtonGroup;
