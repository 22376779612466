import React from 'react';
import PropTypes from 'prop-types';

function SwitchUser({ users, selected, onChange }) {
  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <select id="switch-user" onChange={handleChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" value={selected}>
      <option value="">-- Select user --</option>
      {users.map((user) => (
        <option
          key={user.schoolEmailAddress}
          value={user.schoolEmailAddress}
        >
          {user.fullName}
        </option>
      ))}
    </select>
  );
}

SwitchUser.propTypes = {
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.string,
  users: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    schoolEmailAddress: PropTypes.string,
  })).isRequired,
};

SwitchUser.defaultProps = {
  selected: '',
};

export default SwitchUser;
