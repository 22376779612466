import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
} from '@tanstack/react-table';
import classNames from 'classnames';
import TablePagination from '../table-pagination';
import Toggle from '../toggle';

const globalFilterFn = (row, col, search) => {
  const val = row.getValue(col);
  return val.toString().toLowerCase().includes(search.toLowerCase());
};
const columns = [
  {
    header: 'Name',
    accessorKey: 'fullName',
  },
  {
    header: 'Exam result (avg %)',
    accessorKey: 'averageMark',
  },
  {
    header: 'Baseline mark',
    accessorKey: 'baselineMark',
  },
  {
    header: () => <span className="sr-only">Actions</span>,
    id: 'Actions',
  },
];

function PupilTable({
  pupils, tutees, house, assumedUser,
}) {
  const [showAllPupils, setShowAllPupils] = useState(false);
  const [globalFilter, setGlobalFilter] = useState('');
  const navigate = useNavigate();
  const table = useReactTable({
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    data: showAllPupils ? pupils : tutees,
    globalFilterFn,
    state: {
      globalFilter,
    },
    columns,
  });
  const toggleAllClassNames = classNames('text-xl font-medium', {
    'text-gray-900': showAllPupils,
    'text-gray-400': !showAllPupils,
  });
  const toggleTuteesClassNames = classNames('text-xl font-medium', {
    'text-gray-900': !showAllPupils,
    'text-gray-400': showAllPupils,
  });
  const handleRowClick = (pupil) => {
    navigate(`/pupils/${pupil.schoolId}`);
  };

  return (
    <>
      <div className="flex justify-between items-center mt-3">
        <div className="flex items-center">
          <h2 className={toggleTuteesClassNames}>{house ? `${assumedUser?.boardingHouse} Pupils` : 'My tutees'}</h2>
          <Toggle onChange={(checked) => setShowAllPupils(checked)} />
          <h2 className={toggleAllClassNames}>All pupils</h2>
        </div>
        <label htmlFor="table-search" className="sr-only">Search</label>
        <div className="relative">
          <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
            <svg className="w-5 h-5 text-gray-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" /></svg>
          </div>
          <input type="text" id="table-search" className="block p-2 pl-10 w-80 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-wellington-green-500 focus:border-wellington-green-500 focus-visible:border-wellington-green-500 focus-visible:outline-wellington-green-100" placeholder="Search pupils" onChange={(e) => setGlobalFilter(e.target.value)} value={globalFilter} />
        </div>
      </div>
      <table className="w-full text-sm text-left text-gray-500 mt-3">
        <thead className="text-sm text-gray-900 uppercase bg-gray-50">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} className="py-3 px-6 border-b-2 border-gray-200">
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} className="bg-white border-b hover:bg-gray-50" onClick={() => handleRowClick(row.original)}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <TablePagination table={table} />
    </>
  );
}

PupilTable.propTypes = {
  pupils: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  tutees: PropTypes.arrayOf(PropTypes.shape({})),
  house: PropTypes.bool,
  assumedUser: PropTypes.shape({ boardingHouse: PropTypes.string }),
};

PupilTable.defaultProps = {
  tutees: [],
  house: null,
  assumedUser: null,
};

export default PupilTable;
