import PropTypes from 'prop-types';
import PropTypesReport from './report';

export default PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  endDate: PropTypes.string,
  averagePupilCycleGrade: PropTypes.number,
  averagePupilCycleVA: PropTypes.number,
  averagePupilCycleBaseline: PropTypes.number,
  averageCycleGrade: PropTypes.number,
  averageCycleVA: PropTypes.number,
  averageCycleBaseline: PropTypes.number,
  reports: PropTypes.arrayOf(PropTypesReport),
});
