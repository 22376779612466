import React from 'react';
import PropTypes from 'prop-types';
import PropTypesReport from '../../../__props__/report';

const getValueByKey = (key, report) => {
  switch (key) {
    case 'subject':
      return <>{report.subject} &mdash; {report.author}</>;
    case 'grade':
      if (report.grade) {
        return <>Grade &mdash; {report.grade}</>;
      }
      return <div />;
    case 'mark':
      if (report.mark) {
        return <>Mark &mdash; {report.mark}</>;
      } return <div />;
    case 'result':
      if (report.result) {
        return <>Mark &mdash; {report.result}</>;
      } return <div />;
    default:
      return report[key];
  }
};

function ReportsTableRow({ report, headers }) {
  const keys = Object.keys(headers);
  const dataRow = (
    <tr className="border-t border-gray-200">
      {keys.map((key) => (
        <th scope="rowgroup" className="text-left font-medium py-4 px-6" key={key}>
          {getValueByKey(key, report)}
        </th>
      ))}
    </tr>
  );

  let commentRow;

  if (report.comments) {
    if (Array.isArray(report.comments.Comment)) {
      commentRow = (
        <>
          { report.comments.Comment.map((item) => (
            <tr className="border-t border-gray-200 mb-3">
              <td colSpan={keys.length} className="py-4 px-6">{item.Comment}</td>
            </tr>
          ))}
        </>
      );
    } else {
      commentRow = (
        <tr className="border-t border-gray-200 mb-3">
          <td colSpan={keys.length} className="py-4 px-6">{report.comments.Comment.Comment}</td>
        </tr>
      );
    }
  } else {
    commentRow = null;
  }

  return (
    <>
      {dataRow}
      {commentRow}
    </>
  );
}

ReportsTableRow.propTypes = {
  report: PropTypesReport.isRequired,
  headers: PropTypes.shape({}).isRequired,
};

export default ReportsTableRow;
