import React from 'react';
import PropTypes from 'prop-types';

function RadioButton({
  name,
  label,
  value,
  checked,
  onChange,
}) {
  return (
    <>
      <input id={`radio-button-${name}-${value}`} type="radio" value={value} name={name} checked={checked} className="sr-only peer" onChange={(e) => onChange(e, value)} />
      <label htmlFor={`radio-button-${name}-${value}`} className="flex p-2 cursor-pointer text-gray-500 hover:text-gray-700 hover:bg-gray-100 peer-checked:text-gray-900 peer-checked:bg-wellington-green-100 peer-checked:border-transparent">{label}</label>
    </>
  );
}

RadioButton.defaultProps = {
  checked: false,
};

RadioButton.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]).isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default RadioButton;
