import React, { useCallback, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import RequireAuth from '../components/require-auth';
import Nav from '../components/nav';
import Spinner from '../components/spinner';
import useStore from '../store';
import useAuthStore from '../store/auth';
import Dashboard from './dashboard';
import Redirect from './redirect';
import Pupil from './pupil';
import Help from './help';
import Search from './search';
import Admin from './admin';

const authSelector = (state) => [state.userGroups, state.house, state.isTutor];
const dashboardSelector = (state) => [state.getDashboard, state.dashboard, state.busy, state.error];
const houseSelector = (state) => [state.getHouse, state.house];
const switchUserSelector = (state) => [state.switchUser, state.schoolEmailAddress];
const busySelector = (state) => [state.busy];

// Top-level application router.
function Router() {
  const [userGroups] = useAuthStore(authSelector);
  const [getDashboard, dashboard, isDashboardBusy] = useStore(dashboardSelector);
  const [getHouse, house] = useStore(houseSelector);
  const [switchUser, schoolEmailAddress] = useStore(switchUserSelector);
  const [busy] = useStore(busySelector);

  const handleGetDashboard = useCallback(async () => {
    const response = await getDashboard();
    return response;
  }, [getDashboard]);

  const handleGetHouse = useCallback(async () => {
    const response = await getHouse();
    return response;
  }, [getHouse]);

  const handleSwitchUser = (newSchoolEmailAddress) => {
    switchUser(newSchoolEmailAddress);
  };

  // Effect to fetch the data necessary to render the dashboard. This should run
  // once since the function dependency should not change.
  useEffect(() => {
    handleGetDashboard();
  }, [handleGetDashboard]);

  useEffect(() => {
    if (schoolEmailAddress) {
      handleGetDashboard();
      handleGetHouse();
    }
  }, [schoolEmailAddress, handleGetDashboard, handleGetHouse]);

  useEffect(() => {
    handleGetHouse();
  }, [handleGetHouse]);

  return (
    <BrowserRouter>
      <RequireAuth>
        <div className="flex flex-no-wrap">
          {busy && <Spinner />}
          {dashboard && (
            <>
              <div className="w-64 absolute sm:relative bg-wellington-green-100 shadow flex-col justify-between hidden sm:flex min-h-screen">
                <Nav
                  onSwitchUser={handleSwitchUser}
                  selectedUser={schoolEmailAddress}
                  users={dashboard?.users}
                  activeUser={dashboard.assumedUser}
                  userGroups={userGroups}
                />
              </div>
              <div className="container mx-auto py-6 md:w-4/5 w-11/12 px-6">
                <Routes>
                  <Route path="/redirect" element={<Redirect data={dashboard} busy={isDashboardBusy} />} />
                  <Route path="/pupils/:pupilId" element={<Pupil />} />
                  <Route path="/help" element={<Help />} />
                  <Route path="/search" element={<Search />} />
                  <Route path="/admin" element={<Admin />} />
                  <Route path="/house" element={<Dashboard data={house} busy={isDashboardBusy} house />} />
                  <Route path="/tutor" element={<Dashboard data={dashboard} busy={isDashboardBusy} />} />
                  <Route path="*" element={<Redirect to="/" data={dashboard} busy={isDashboardBusy} />} />
                </Routes>
              </div>
            </>
          )}
        </div>
      </RequireAuth>
    </BrowserRouter>
  );
}

export default Router;
