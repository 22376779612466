import React from 'react';
import PropTypes from 'prop-types';

function TablePagination({ table }) {
  const currentPage = table.getState().pagination.pageIndex + 1;
  const totalPages = table.getPageCount();

  const nextPage = (e) => {
    e.preventDefault();

    if (table.getCanNextPage()) {
      table.nextPage();
    }
  };

  const prevPage = (e) => {
    e.preventDefault();

    if (table.getCanPreviousPage()) {
      table.previousPage();
    }
  };

  const prevPageButton = table.getCanPreviousPage() ? (
    <li>
      <a href="#" className="block w-12 text-center py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700" onClick={prevPage}>{currentPage - 1}</a>
    </li>
  ) : null;
  const nextPageButton = table.getCanNextPage() ? (
    <li>
      <a href="#" className="block w-12 text-center py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700" onClick={nextPage}>{currentPage + 1}</a>
    </li>
  ) : null;
  const currPageButton = (
    <li>
      <a href="#" aria-current="page" className="block relative w-12 text-center py-2 px-3 leading-tight text-gray-900 bg-wellington-green-100 border border-wellington-green-300" onClick={(e) => e.preventDefault()}>{currentPage}</a>
    </li>
  );

  return (
    <nav className="flex justify-between items-center pt-4" aria-label="Table navigation">
      <span className="text-sm font-normal text-gray-500">Showing page <span className="font-semibold text-gray-900">{currentPage}</span> of <span className="font-semibold text-gray-900">{totalPages}</span></span>
      <ul className="inline-flex items-center -space-x-px">
        <li>
          <a href="#" className="block w-12 text-center py-2 px-3 ml-0 leading-tight text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700" onClick={prevPage}>
            <span className="sr-only">Previous</span>
            <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" /></svg>
          </a>
        </li>
        {prevPageButton}
        {currPageButton}
        {nextPageButton}
        <li>
          <a href="#" className="block w-12 text-center py-2 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700" onClick={nextPage}>
            <span className="sr-only">Next</span>
            <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>
          </a>
        </li>
      </ul>
    </nav>
  );
}

TablePagination.propTypes = {
  table: PropTypes.shape({
    getState: PropTypes.func,
    getPageCount: PropTypes.func,
    previousPage: PropTypes.func,
    nextPage: PropTypes.func,
    getCanNextPage: PropTypes.func,
    getCanPreviousPage: PropTypes.func,
  }).isRequired,
};

export default TablePagination;
