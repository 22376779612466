import React from 'react';
import PropTypes from 'prop-types';
import PropTypesReport from '../../__props__/report';
import ReportsTableRow from './row';

function ReportsTable({ reports }) {
  const headersByKey = {
    subject: 'Subject',
    grade: 'Grade',
    mark: 'Mark',
    result: 'Result',
  };

  return (
    <table className="w-full text-gray-900 border border-gray-200">
      <tbody>
        {reports.map((report) => (
          <ReportsTableRow report={report} headers={headersByKey} key={report.id} />
        ))}
      </tbody>
    </table>
  );
}

ReportsTable.propTypes = {
  reports: PropTypes.arrayOf(PropTypesReport).isRequired,
};

export default ReportsTable;
