import create from 'zustand';

export default create((set, get) => ({
  authenticated: false,
  cognito: null,
  userGroups: [],
  handleSignIn: (payload) => set({
    authenticated: true,
    cognito: payload,
    userGroups: payload?.signInUserSession?.accessToken?.payload?.['cognito:groups'],
  }),
}));
