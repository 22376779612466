import React from 'react';
import image from '../../assets/loading.gif';

function Spinner() {
  return (
    <div role="status" className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center z-50">
      <img src={image} alt="Loading..." className="w-20" />
      <span className="sr-only">Loading...</span>
    </div>
  );
}

export default Spinner;
