import React from 'react';
import PropTypes from 'prop-types';
import { Chart, registerables } from 'chart.js';
import { Line } from 'react-chartjs-2';
import PropTypesDashboardPupil from '../../__props__/dashboard-pupil';

// Configure Chart.js. It's necessary to register all of the elements used by
// the scatter chart because they are tree-shaken away otherwise.
Chart.register(...registerables);

const CHART_LINE_COLOR_PUPIL = '#f27d00';
const CHART_LINE_COLOR_YEAR = '#8EB8AC';
const CHART_OPTIONS = {
  responsive: true,
  scales: {
    x: {
      title: {
        display: true,
        text: 'Report cycle',
        color: '#111827',
        padding: 12,
        font: {
          family: 'Euclid Circular A',
          size: 16,
          weight: 500,
        },
      },
      ticks: {
        color: '#111827',
        font: {
          size: 14,
        },
      },
    },
    y: {
      title: {
        display: true,
        text: 'Average mark (%)',
        color: '#111827',
        padding: 12,
        font: {
          family: 'Euclid Circular A',
          size: 16,
          weight: 500,
        },
      },
      ticks: {
        color: '#111827',
        font: {
          size: 14,
        },
      },
    },
  },
  plugins: {
    legend: {
      display: true,
    },
  },
};

function ChartPupilResults({ pupil, reportCycles }) {
  // Utility function to return data in the format required by Chart.js.

  const getChartData = () => ({
    labels: reportCycles.map((reportCycle) => reportCycle.cycleName),
    datasets: [
      {
        label: 'Pupil Average',
        data: reportCycles.map((reportCycle) => reportCycle.pupilAverageMark),
        backgroundColor: CHART_LINE_COLOR_PUPIL,
        borderColor: CHART_LINE_COLOR_PUPIL,
      },
      {
        label: 'Year Average',
        data: reportCycles.map((reportCycle) => reportCycle.cycleAverageMark),
        backgroundColor: CHART_LINE_COLOR_YEAR,
        borderColor: CHART_LINE_COLOR_YEAR,
      },
    ],
  });

  return (
    <Line
      options={CHART_OPTIONS}
      data={getChartData()}
    />
  );
}

ChartPupilResults.propTypes = {
  pupil: PropTypesDashboardPupil.isRequired,
  reportCycles: PropTypes.arrayOf(PropTypesDashboardPupil).isRequired,
};

export default ChartPupilResults;
