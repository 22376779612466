import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypesReportCycle from '../../__props__/report-cycle';
import ReportsTable from '../reports-table';

function ReportCycle({ cycle }) {
  const [hidden, setHidden] = useState(true);
  const count = cycle?.reports?.length ?? 0;
  const qualifier = count === 1 ? 'report' : 'reports';

  return (
    <div className="mb-4">
      <button type="button" onClick={() => setHidden(!hidden)} className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-900 border border-gray-200 bg-gray-100 focus:ring-4 focus:ring-gray-200 hover:bg-gray-100" aria-expanded="true" aria-controls="accordion-open-body-1">
        <h3 className="flex items-center text-lg">{cycle.cycleName} ({count} {qualifier})</h3>
        <svg data-accordion-icon className={classNames(['w-6', 'h-6', 'shrink-0', { 'rotate-180': !hidden }])} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" /></svg>
      </button>
      <div className={classNames(['report-cycle--reports', { hidden }])}>
        <ReportsTable reports={cycle?.reports} />
      </div>
    </div>
  );
}

ReportCycle.propTypes = {
  cycle: PropTypesReportCycle.isRequired,
};

export default ReportCycle;
