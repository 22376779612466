/* eslint-disable react/prop-types */
import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuthStore from '../../store/auth';

const selector = (state) => [state.authenticated];

function Redirect({ data }) {
  const [authenticated] = useAuthStore(selector);

  const isTutor = data?.assumedUser?.tutees.length > 0;

  if (authenticated && data) {
    const route = isTutor === true ? '/tutor' : '/house';
    return <Navigate to={route} replace />;
  }

  return (
    <div className="page--redirect" />
  );
}

export default Redirect;
