import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useStore from '../../store';
import ChartPupilResults from '../../components/chart-pupil-results';
import PupilReports from './reports';

const selector = (state) => [state.getPupil, state.busy, state.error];

function Pupil() {
  const { pupilId } = useParams();
  const [getPupil] = useStore(selector);
  const pupil = useStore(useCallback((state) => state.pupils[pupilId], [pupilId]));

  useEffect(() => {
    const effect = async () => {
      const response = await getPupil(pupilId);
      return response;
    };

    if (pupil?.info?.schoolId !== pupilId) {
      effect();
    }
  }, [getPupil, pupilId, pupil]);

  return (
    <div className="page--pupil container mx-auto">
      {pupil && (
        <>
          <h1 className="text-3xl leading-6 font-medium text-gray-900 mb-6 mt-3 px-4 py-4 bg-wellington-orange">{pupil?.info?.fullName}</h1>
          <div className="bg-gray-100 p-4">
            <ChartPupilResults
              pupil={pupil}
              reportCycles={[...pupil.reportCycles].reverse().filter((cycle) => cycle.cycleType === 'EXAM' || cycle.cycleType === 'REPORT_GB' || cycle.cycleType === 'EXAM_GB').filter((cycle) => cycle.pupilAverageMark
              > 0)}
            />
          </div>
          <PupilReports pupil={pupil} />
        </>
      )}
    </div>
  );
}

export default Pupil;
