import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import SwitchUser from '../switch-user';
import logo from '../../assets/logo.webp';

function Nav({
  users,
  selectedUser,
  onSwitchUser,
  activeUser,
  userGroups,
}) {
  const {
    salutation, initials,
  } = activeUser;

  const isAdmin = userGroups?.includes('admins');
  const isTutor = activeUser?.tutees.length > 0;
  const handleSignOut = async () => {
    await Auth.signOut();
  };

  return (
    <aside className="w-64 h-full" aria-label="Sidebar">
      <div className="overflow-y-auto py-4 px-3 h-full">
        <img src={logo} alt="Wellington College logo" className="w-20" />
        <h2 className="text-2xl font-medium tracking-tight text-gray-900 mb-3 mt-3">Welcome</h2>
        <div className="flex items-center mb-3">
          <div className="w-11 h-11 bg-wellington-green-800 rounded-full text-white text-xl flex items-center justify-center shrink-0">
            {initials}
          </div>
          <p className="ml-4 text-gray-900 font-medium text-lg">{salutation}</p>
        </div>
        <ul className="space-y-2 border-t border-wellington-green-700 pt-4 mt-4">
          {isTutor && (
          <li>
            <Link to="/tutor" className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-wellington-green-300">
              <svg className="w-6 h-6 text-gray-900" fill="none" stroke="currentColor" strokeWidth={2} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 14l9-5-9-5-9 5 9 5z" /><path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" /><path d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222" /></svg>
              <span className="ml-3">My tutees</span>
            </Link>
          </li>
          )}
          <li>
            <Link to="/house" className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-wellington-green-300">
              <svg className="w-6 h-6 text-gray-900" fill="none" stroke="currentColor" strokeWidth={2} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" /></svg>
              <span className="ml-3">{activeUser.boardingHouse}</span>
            </Link>
          </li>
          {isAdmin && (
          <li>
            <Link to="/search" className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-wellington-green-300">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-900 transition duration-75 group-hover:text-gray-900" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
              </svg>
              <span className="ml-3">Search</span>
            </Link>
          </li>
          )}
          <li>
            <Link to="/help" className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-wellington-green-300">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-900 transition duration-75 group-hover:text-gray-900" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <span className="ml-3">Help</span>
            </Link>
          </li>
          {isAdmin && (
            <li>
              <Link to="/admin" className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-wellington-green-300">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-900 transition duration-75 group-hover:text-gray-900" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
                <span className="ml-3">Admin</span>
              </Link>
            </li>
          )}
          <li>
            <button type="button" className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg w-full text-left hover:bg-wellington-green-300" onClick={handleSignOut}>
              <svg className="w-6 h-6 text-gray-900 transition duration-75 group-hover:text-gray-900" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" /></svg>
              <span className="flex-1 ml-3 whitespace-nowrap">Sign out</span>
            </button>
          </li>
        </ul>
        {isAdmin && (
          <ul className="pt-4 mt-4 space-y-2 border-t border-wellington-green-700">
            <h2 className="text-xl font-medium text-gray-900 mb-3">Switch user</h2>
            <li>
              <SwitchUser users={users} selected={selectedUser} onChange={onSwitchUser} />
            </li>
          </ul>
        )}
      </div>
    </aside>
  );
}

Nav.propTypes = {
  onSwitchUser: PropTypes.func.isRequired,
  selectedUser: PropTypes.string,
  users: PropTypes.arrayOf(PropTypes.shape),
  activeUser: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    salutation: PropTypes.string,
    initials: PropTypes.string,
    tutees: PropTypes.arrayOf(PropTypes.shape),
    boardingHouse: PropTypes.string,
  }).isRequired,
  userGroups: PropTypes.arrayOf(PropTypes.string),
};

Nav.defaultProps = {
  selectedUser: '',
  users: [],
  userGroups: [],
};

export default Nav;
