import React from 'react';
import ReportCycle from '../../../components/report-cycle';
import PropTypesDashboardPupil from '../../../__props__/dashboard-pupil';

function PupilReports({ pupil }) {
  return (
    <div className="mt-6">
      <h2 className="text-2xl font-medium text-gray-900 mb-7 bg-wellington-yellow px-3 py-3">Reports</h2>
      {pupil.reportCycles
        && pupil.reportCycles.map((cycle) => <ReportCycle key={cycle.id} cycle={cycle} />)}
    </div>
  );
}

PupilReports.propTypes = {
  pupil: PropTypesDashboardPupil.isRequired,
};

export default PupilReports;
