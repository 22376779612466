import PropTypes from 'prop-types';

const propTypes = PropTypes.shape({
  schoolId: PropTypes.string.isRequired,
  averageMark: PropTypes.number,
  baselineMark: PropTypes.number,
  fullName: PropTypes.string,
});

export default propTypes;
