import React from 'react';
import PropTypes from 'prop-types';

import ButtonGroup from '../button-group';
import RadioButton from '../button-radio';

function YearGroupSelector({
  yearGroups,
  onChange,
}) {
  return yearGroups?.length > 1 ? (
    <div className="w-min inline-flex align-baseline mb-2">
      <label htmlFor="year-group-selector" className="inline-block leading-10 pr-3 truncate font-medium text-gray-900">Year group</label>
      <ButtonGroup id="year-group-selector">
        {yearGroups?.map((yearGroup) => (
          <RadioButton
            name="year-group"
            key={`year-group-${yearGroup.value}`}
            label={yearGroup.label}
            value={yearGroup.value}
            checked={yearGroup.checked}
            onChange={onChange}
          />
        ))}
      </ButtonGroup>
    </div>
  ) : null;
}

YearGroupSelector.propTypes = {
  yearGroups: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.number.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]).isRequired,
    checked: PropTypes.bool,
  })).isRequired,
  onChange: PropTypes.func.isRequired,

};

export default YearGroupSelector;
